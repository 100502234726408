@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  font-size: 20px;
  --white: #ffffff;
  --blue-light-2: #d6e0ec;
  --blue-light: #f4f3f9;
  --blue-2: #88cefb;
  --blue-sec: #0398f8;
  --blue: #19a3fc;
  --pink-light: #fef7fc;
  --pink-2: #f086d3;
  --pink: #e742ba;
  --blue-dark-4: #353746;
  --blue-dark-3: #6c6f90;
  --blue-dark-2: #404255;
  --blue-dark: #1f2029;
  --black: #000000;
  --success: #2ecc71;
  --danger: #e74c3c;
  --warning: #f8e287;
  --info: #9b59b6;
}


@media (min-width: 1200px) { /* This ensures it only applies on extra-large screens (xl) */
  .col-xl-custom {
    max-height: 60%;
    flex: 2% 2% 40%; /* This width corresponds to approximately between col-xl-5 and col-xl-4 */
    max-width: 40%;
  }
}

.link {
  color: #007bff;
  cursor: pointer;
}

.load {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  z-index: 100000;
  opacity: 0.3;
}
.load:after {
  width: 36px;
  height: 36px;
  position: absolute;
  content: "";
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
  border-radius: 50%;
  z-index: 1000000000;
}
.load:after {
  opacity: 0.2;
  border: none;
  background-color: var(--blue);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}
@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

/* #Primary
================================================== */

body {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: var(--blue-dark-2);
  background-color: var(--blue-light);
  overflow-x: hidden;
}

.app {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: var(--blue-dark-2);
  background-color: var(--blue-light);
  overflow-x: hidden;
}

p {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  letter-spacing: 0.2px;
  color: var(--blue-dark-2);
}
p.lead {
  font-size: 18px;
  line-height: 27px;
}
p.small {
  font-size: 13px;
  line-height: 18px;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-family: "Roboto", sans-serif;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1;
  color: var(--blue-dark-2);
}
h1,
.h1 {
  font-size: 48px;
  line-height: 58px;
}
h2,
.h2 {
  font-size: 38px;
  line-height: 46px;
}
h3,
.h3 {
  font-size: 32px;
  line-height: 40px;
}
h4,
.h4 {
  font-size: 26px;
  line-height: 32px;
}
h5,
.h5 {
  font-size: 22px;
  line-height: 30px;
}
h6,
.h6 {
  font-size: 18px;
  line-height: 26px;
  font-weight: 700;
}
::selection {
  color: var(--white);
  background-color: var(--blue);
}
::-moz-selection {
  color: var(--white);
  background-color: var(--blue);
}
mark {
  color: var(--white);
  background-color: var(--blue);
}
a {
  color: var(--blue);
  transition: all 200ms linear;
}
a:hover {
  color: var(--blue-2);
  text-decoration: none !important;
}

/* #Preloader style
================================================== */

.animsition {
  min-height: 100vh;
}
.animsition-loading,
.animsition-loading:after {
  width: 36px;
  height: 36px;
  position: fixed;
  top: 50%;
  left: 50%;
  margin-top: -18px;
  margin-left: -18px;
  border-radius: 50%;
  z-index: 100;
}
.animsition-loading {
  opacity: 0.2;
  background-color: transparent;
  border: none;
  background-color: var(--blue);
  animation: loaderAnim 0.7s linear infinite alternate forwards;
}
@keyframes loaderAnim {
  to {
    opacity: 1;
    transform: scale3d(0.5, 0.5, 1);
  }
}

/* #Main style
================================================== */

.section {
  position: relative;
  width: 100%;
  display: block;
}
.over-hide {
  overflow: hidden;
}
.z-bigger-10 {
  z-index: 10;
}
.z-bigger-20 {
  z-index: 20;
}
.z-bigger-30 {
  z-index: 30;
}
.z-bigger-40 {
  z-index: 40;
}
.full-height {
  height: 100vh;
}
.img-wrap img {
  width: 100%;
  height: auto;
  display: block;
}
.border-2 {
  border-radius: 2px;
}
.border-4 {
  border-radius: 4px;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-700 {
  font-weight: 700;
}
.cursor-pointer {
  cursor: pointer;
}

/* #Header
================================================== */

.header {
  position: fixed;
  left: 0;
  top: 0;
  height: 60px;
  width: 100%;
  background-color: var(--white);
  z-index: 99;
}
.navbar {
  padding: 0;
  margin: 0;
}
.header-height {
  height: 60px;
}
.client-logo {
  position: relative;
  height: 46px;
  display: block;
  z-index: 9;
}
.client-logo img {
  height: 100%;
  width: auto;
  display: block;
}
.user-section {
  position: relative;
  cursor: pointer;
  border-radius: 2px;
  height: 40px;
  z-index: 10;
  background-color: var(--blue-light);
  color: var(--blue-dark-2);
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  padding: 0 12px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  transition: all 200ms linear;
}
.user-section:hover {
  opacity: 0.8;
}
.user-img img {
  width: 30px;
  height: 30px;
  display: block;
}
.down-img {
  transition: all 200ms linear;
}
.down-img img {
  width: 10px;
  height: 10px;
  display: block;
  transition: all 200ms linear;
}
.user-section[aria-expanded="true"] .down-img {
  transform: rotate(180deg);
}

.dropdown-menu {
  padding: 15px 20px;
  margin-top: 10px;
  border-radius: 2px;
  box-shadow: 0px 6px 24px rgba(64, 66, 85, 0.08);
  min-width: 130px;
  background-color: var(--white);
  border: none;
  display: block;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  transform: translate(0, 10px);
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
}
.dropdown-menu.show {
  transform: translate(0, 0);
  visibility: visible;
  pointer-events: auto;
  opacity: 1;
}

/* #Left side nav
================================================== */

.sec-move-left {
  padding-left: 60px;
  transition: all 200ms linear;
}

.left-side-nav {
  position: fixed;
  left: 0;
  top: 0;
  height: 100%;
  width: 60px;
  background-color: var(--blue-dark-2);
  z-index: 100;
}
.left-logo {
  position: relative;
  width: 60px;
  height: 60px;
  background-color: var(--blue-dark);
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
}
.left-logo img {
  width: 40px;
  height: 40px;
  display: block;
}
.left-side-nav-link {
  position: relative;
  width: 60px;
  height: 60px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border-bottom: 1px solid rgba(31, 32, 41, 0.41);
  transition: all 200ms linear;
}
.left-side-nav-link img {
  width: 22px;
  height: 22px;
  display: block;
}
.left-side-nav-link:hover {
  background-color: var(--blue-dark-4);
}
.left-side-nav-link.active {
  background-color: var(--blue);
}

/* #Left side sub nav
================================================== */

.left-side-sub-nav {
  position: fixed;
  left: -160px;
  top: 60px;
  height: calc(100% - 60px);
  width: 220px;
  background-color: var(--white);
  box-shadow: 8px 0px 12px rgba(64, 66, 85, 0.04);
  transition: all 200ms linear;
  z-index: 90;
}
body.opened-sub-nav .left-side-sub-nav {
  left: 60px;
}
.left-side-title {
  position: relative;
  width: 100%;
  padding: 0 15px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  height: 46px;
  font-weight: 500;
  font-size: 16px;
  color: var(--blue-dark-2);
  background-color: rgba(237, 245, 251, 0.65);
}

.sub-menu-link {
  position: relative;
  height: 32px;
  padding: 0 15px;
  padding-left: 25px;
  font-size: 14px;
  line-height: 32px;
  font-weight: 400;
  width: 100%;
  color: var(--blue-dark-2);
  background-color: var(--white);
  display: -webkit-box;
  display: flex;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
  transition: all 200ms linear;
  margin-bottom: 1px;
  cursor: pointer;
}
.sub-menu-link:hover {
  color: var(--blue-dark-2);
  background-color: rgba(237, 245, 251, 0.85);
}
.sub-menu-link.active {
  background-color: rgba(237, 245, 251, 0.65);
}

.submenu-sub-accordion .sub-menu-link {
  padding-left: 35px;
}

.sub-nav-close {
  position: absolute;
  cursor: pointer;
  width: 20px;
  height: 26px;
  top: 10px;
  right: -20px;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  background-color: var(--white);
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  transition: all 200ms linear;
}
.sub-nav-close:hover {
  background-color: rgba(237, 245, 251, 0.85);
}
.sub-nav-close img {
  width: 10px;
  height: 10px;
  display: block;
  transform: rotate(180deg);
}
body.opened-sub-nav .sub-nav-close img {
  transform: rotate(0);
}

body.opened-sub-nav .sec-move-left {
  padding-left: 280px;
}

/* #Tooltip
================================================== */

.tooltip {
  font-weight: 400 !important;
  line-height: 1.5 !important;
  font-size: 14px !important;
  filter: drop-shadow(4px 8px 14px rgba(64, 66, 85, 0.25)) !important;
}
.tooltip.show {
  opacity: 1;
}
.tooltip-inner {
  padding: 10px 15px !important;
  max-width: 300px !important;
  color: rgba(64, 66, 85, 0.75) !important;
  text-align: left !important;
  background-color: var(--white) !important;
  border-radius: 2px !important;
}

.bs-tooltip-top .arrow::before,
.bs-tooltip-auto[x-placement^="top"] .arrow::before {
  border-top-color: var(--white) !important;
}
.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: var(--white) !important;
}
.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: var(--white) !important;
}
.bs-tooltip-left .arrow::before,
.bs-tooltip-auto[x-placement^="left"] .arrow::before {
  border-left-color: var(--white) !important;
}

/* #Intro
================================================== */
.intro-profile-main {
width: 500px !important;
max-width: 1000px !important;
}
.intro-profile-category-add-hightlight {
  height: 290px !important;
  max-height: 1000px !important;
  }
.intro-main-welcome{
  width: 500px !important;
  max-width: 1000px !important;
}
.intro-main-filters{
  width: 450px !important;
  max-width: 450px !important;
}

.intro-card-body-hightlight{
  /* margin-left: 225px !important; */
  height: 330px !important;
}
.intro-card-body{
  /* margin-left: 225px !important; */
}
.intro-export-hightlight{
  margin-top: 270px !important;
  height: 60px !important;
}
.intro-export{
  margin-top: 270px !important;
}
.intro-archive-hightlight{
  height: 70px !important;
  max-height: 70px !important;
}
.intro-archive{
}
.intro-searches-hightlight{
  /* margin-top: 410px !important; */
  height: 100px !important;
}
.intro-searches{
  /* margin-top: 410px !important; */
}
.intro-settings{
  /* margin-left: 220px !important; */
}
.intro-settings-hightlight{
  /* margin-left: 220px !important; */
}
.intro-reports-calendar{
  height: 150px !important;
  width: 265px !important;
  max-width: 265px !important;
  max-height: 140px !important;
  top: 85px !important;
}
.intro-profile-categories{
  margin-right: 1000px;
}
.intro-profile-categories-hightlight{
  height: 500px !important;
  max-height: 500px !important; 
}
/* #Buttons & links
================================================== */

.btn {
  height: 50px;
  font-size: 17px;
  letter-spacing: 0;
  line-height: 1;
  font-weight: 500;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
  padding: 0 20px;
  max-width: 100%;
  position: relative;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
}
.btn:active,
.btn:focus {
  box-shadow: none;
}
.btn-width-80 {
  min-width: 80px;
}
.btn-width-100 {
  min-width: 100px;
}
.btn-width-130 {
  min-width: 130px;
}
.btn-width-170 {
  min-width: 170px;
}
.btn-height-30 {
  height: 30px;
}
.btn-height-32 {
  height: 32px;
}
.btn-height-36 {
  height: 36px;
}
.btn-height-40 {
  height: 40px;
}

.btn-blue {
  color: var(--white) !important;
  background-color: var(--blue) !important;
}
.btn-blue:hover {
  color: var(--white);
  background-color: var(--blue-sec);
  box-shadow: 0 12px 24px 0 rgba(25, 163, 252, 0.25);
}
.btn-grey {
  color: var(--blue-dark-3);
  background-color: var(--blue-light-2);
}
.btn-grey:hover {
  color: var(--white);
  background-color: var(--blue-dark-2);
}
.btn-dark {
  color: var(--white);
  background-color: var(--blue-dark-3);
}
.btn-dark:hover {
  color: var(--white);
  background-color: var(--black);
}

.link-dark {
  color: var(--blue-dark-2);
  opacity: 0.8;
  transition: all 200ms linear;
}
.link-dark:hover {
  color: var(--blue-dark-2);
  opacity: 0.5;
}
.link-dark img {
  width: 12px;
  height: 12px;
  display: inline-block;
  transform: translateY(-1px);
}

/* #Forms
================================================== */

.form-group {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
}
.form-group label {
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: var(--blue-dark-2);
}
.form-group.error label{
  font-size: 12px;
  color: var(--danger)
}
.form-style {
  padding: 18px 20px;
  height: 60px;
  width: 100%;
  font-weight: 500;
  border-radius: 4px;
  font-size: 18px;
  line-height: 24px;
  outline: none;
  color: var(--blue-dark-2);
  background-color: var(--blue-light);
  border: none;
  transition: all 200ms linear;
}
.form-style.white {
  background-color: var(--white);
}
.form-style.error {
  border: 1px solid var(--danger) !important
}
.form-style:focus,
.form-style:active {
  border: none;
  outline: none;
  box-shadow: none;
}

.form-group input:placeholder {
  color: var(--blue-dark);
  font-weight: 400;
  font-style: italic;
  opacity: 0.5;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:-ms-input-placeholder {
  color: var(--blue-dark);
  font-weight: 400;
  font-style: italic;
  opacity: 0.5;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input::-moz-placeholder {
  color: var(--blue-dark);
  font-weight: 400;
  font-style: italic;
  opacity: 0.5;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:-moz-placeholder {
  color: var(--blue-dark);
  font-weight: 400;
  font-style: italic;
  opacity: 0.5;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input::-webkit-input-placeholder {
  color: var(--blue-dark);
  font-weight: 400;
  font-style: italic;
  opacity: 0.5;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-group input:focus:placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus:-ms-input-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus::-moz-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus:-moz-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}
.form-group input:focus::-webkit-input-placeholder {
  opacity: 0;
  -webkit-transition: all 200ms linear;
  transition: all 200ms linear;
}

.form-style.small-32 {
  padding: 6px 15px;
  height: 32px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
}
.form-style.small-40 {
  padding: 8px 15px;
  height: 40px;
  font-size: 15px;
  line-height: 24px;
}
.form-style.small {
  padding: 13px 20px;
  height: 50px;
  font-size: 16px;
  line-height: 24px;
}

/* #Modals
================================================== */

.modal-content {
  width: 100%;
  max-width: 440px;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: subpixel-antialiased;
  border-radius: 4px;
}
.modal-dialog {
  position: absolute;
  width: auto;
  top: 50%;
  left: 50%;
  pointer-events: none;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: 440px;
  margin: 0;
  padding: 0;
  border-radius: 4px;
  max-height: 100vh;
}
.modal.fade .modal-dialog {
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(-50%, -55%);
  transform: translate(-50%, -55%);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
}
.modal {
  z-index: 1121050;
  margin: 0;
  padding: 0;
  border-radius: 4px;
}
.modal-backdrop {
  z-index: 1121040;
  background-color: var(--blue-dark-2);
}
.modal.show .modal-dialog {
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.modal-backdrop.show {
  opacity: 0.89;
}
.modal-body {
  margin: 0;
  padding: 40px 10px;
  border-radius: 4px;
}
.modal-content {
  position: relative;
  border: none;
  box-shadow: 8px 14px 38px 0 rgba(31, 32, 41, 0.4);
  margin: 0;
  padding: 0;
  background-color: var(--white);
  border-radius: 4px;
}
.modal-footer {
  border-top: none;
  margin: 0;
  padding: 0;
}
.modal-body .close {
  position: absolute;
  height: 28px;
  width: 28px;
  border-radius: 0;
  top: 20px;
  right: 20px;
  padding: 0;
  margin: 0;
  opacity: 1;
  text-shadow: none;
  font-weight: normal;
  background-color: transparent;
  color: var(--blue-dark);
  cursor: pointer;
  z-index: 100;
  opacity: 0.7;
  transition: all 200ms linear;
}
.modal-body .close:after,
.modal-body .close:before {
  position: absolute;
  content: "";
  height: 2px;
  width: 100%;
  top: 50%;
  left: 0;
  background-color: var(--blue-dark);
  z-index: 100;
  transition: all 200ms linear;
}
.modal-body .close:after {
  transform: rotate(45deg);
}
.modal-body .close:before {
  transform: rotate(-45deg);
}
.modal-body .close:hover:after,
.modal-body .close:hover:before {
  transform: rotate(0);
}
.modal-body .close:hover {
  background-color: transparent;
}
.modal-body .close:active,
.modal-body .close:focus {
  border: none;
  outline: none;
  box-shadow: none;
}

/* #Select2
================================================== */

.select2-container {
  width: 100% !important;
}
.select2-container--bootstrap4 .select2-selection--single {
  height: 50px !important;
  width: 100% !important;
}

.height-40-style .select2-container--bootstrap4 .select2-selection--single {
  height: 40px !important;
}
.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--blue-dark-2);
  font-weight: 500;
  line-height: 24px;
}
.input-select-dark-version
  + .select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__placeholder {
  color: rgba(255, 255, 255, 0.7);
}
.dark-version
  .select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__placeholder {
  color: var(--white) !important;
}
.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow {
  position: absolute;
  top: 50%;
  right: 3px;
  width: 20px;
}
.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-bottom: 2px solid var(--blue-dark);
  border-right: 2px solid var(--blue-dark);
  display: block;
  height: 10px;
  width: 10px;
  margin-top: -6px;
  right: 16px;
  top: 50%;
  opacity: 0.7;
  -webkit-transform-origin: 66% 66%;
  -ms-transform-origin: 66% 66%;
  transform-origin: 66% 66%;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  position: absolute;
}

.select-white
  .select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow
  b {
  opacity: 0.4;
  height: 9px;
  width: 9px;
  margin-top: -5px;
}

.input-select-dark-version
  + .select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow
  b {
  border-bottom: 2px solid var(--white) !important;
  border-right: 2px solid var(--white) !important;
}

.select2-container--bootstrap4
  .select2-selection--single[aria-expanded="true"]
  .select2-selection__arrow
  b {
  -webkit-transform: rotate(225deg);
  -ms-transform: rotate(225deg);
  transform: rotate(225deg);
}
.select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__rendered {
  line-height: 24px;
}

.select2-search--dropdown .select2-search__field {
  border: none;
  border-radius: 2px;
  background-color: var(--blue-light);
  padding: 12px 20px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  color: var(--blue-dark-2);
}

.select2-dropdown.dark-version-style
  .select2-search--dropdown
  .select2-search__field {
  background-color: var(--blue-dark);
  color: var(--white);
}

.select2-dropdown.dark-version-style {
  background-color: var(--black);
}

.select2-dropdown.dark-version-style .select2-results__option {
  color: rgba(255, 255, 255, 0.9);
}
.select2-dropdown.dark-version-style .select2-results__group {
  color: var(--white) !important;
}

.select2-container .select2-search--inline {
  position: absolute;
  bottom: -50px;
  left: 0;
  width: 100% !important;
  padding: 0;
}
.select2-container .select2-search--inline .select2-search__field {
  margin-top: 10px;
  border: none;
  height: 50px;
  outline: none;
  padding: 0 17px !important;
  border: none;
  width: 100% !important;
  background-color: var(--dark) !important;
  color: var(--white) !important;
}

.select2-search__field::placeholder {
  color: var(--blue-light) !important;
  opacity: 1 !important;
}
.select2-search--inline .select2-search__field:-ms-input-placeholder {
  color: var(--blue-light) !important;
  opacity: 1 !important;
}
.select2-search--inline .select2-search__field::-moz-placeholder {
  color: var(--blue-light) !important;
  opacity: 1 !important;
}
.select2-search--inline .select2-search__field:-moz-placeholder {
  color: var(--blue-light) !important;
  opacity: 1 !important;
}
.select2-search--inline .select2-search__field:-webkit-input-placeholder {
  color: var(--blue-light) !important;
  opacity: 1 !important;
}

.select2-container--bootstrap4
  .select2-dropdown.dark-version-style-multiple
  .select2-results__option[aria-selected="true"] {
  background-color: var(--blue);
}
.select2-dropdown.dark-version-style-multiple
  .select2-search--dropdown
  .select2-search__field {
  background-color: var(--blue-dark);
  color: var(--white);
}
.select2-dropdown.dark-version-style-multiple {
  background-color: var(--dark);
  top: 50px !important;
}
.select2-dropdown.dark-version-style-multiple .select2-results__option {
  color: rgba(255, 255, 255, 0.9);
}
.select2-dropdown.dark-version-style-multiple .select2-results__group {
  color: var(--white) !important;
}

ul#select2-le2d-container.select2-selection__rendered {
  color: var(--white) !important;
}

.input-select-light-multiple
  + .select2-container
  .select2-search--inline
  .select2-search__field {
  background-color: var(--blue-light) !important;
  color: var(--blue-dark) !important;
}
.input-select-light-multiple
  + .select2-container
  .select2-search--inline
  .select2-search__field::placeholder {
  color: var(--blue-dark) !important;
  opacity: 1 !important;
}
.input-select-light-multiple
  + .select2-container
  .select2-search--inline
  .select2-search__field:-ms-input-placeholder {
  color: var(--blue-dark) !important;
  opacity: 1 !important;
}
.input-select-light-multiple
  + .select2-container
  .select2-search--inline
  .select2-search__field::-moz-placeholder {
  color: var(--blue-dark) !important;
  opacity: 1 !important;
}
.input-select-light-multiple
  + .select2-container
  .select2-search--inline
  .select2-search__field:-moz-placeholder {
  color: var(--blue-dark) !important;
  opacity: 1 !important;
}
.input-select-light-multiple
  + .select2-container
  .select2-search--inline
  .select2-search__field:-webkit-input-placeholder {
  color: var(--blue-dark) !important;
  opacity: 1 !important;
}

.select2-container--bootstrap4
  .select2-dropdown.light-version-style-multiple
  .select2-results__option[aria-selected="true"] {
  background-color: var(--blue);
  color: var(--white);
}
.select2-dropdown.light-version-style-multiple
  .select2-search--dropdown
  .select2-search__field {
  background-color: var(--blue-light);
  color: var(--white) !important;
}
.select2-dropdown.light-version-style-multiple {
  background-color: var(--white);
  top: 50px !important;
}
.select2-dropdown.light-version-style-multiple .select2-results__group {
  color: var(--dark) !important;
}
.select2-dropdown.light-version-style-multiple .select2-results__option {
  color: rgba(21, 21, 21, 0.9);
}

.select2-results__message {
  color: #6c757d;
}

.select2-container--bootstrap4 .select2-selection--multiple {
  min-height: 50px !important;
}
.select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__rendered {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__rendered
  .select2-search__field {
  width: 100% !important;
}
.select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__choice {
  color: var(--white);
  background-color: var(--blue-2);
  border: none;
  border-radius: 2px;
  padding: 0;
  height: 31px;
  line-height: 26px;
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
  float: left;
  margin: 0;
  margin-left: 6px;
  margin-bottom: 6px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 3px;
}
.select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__choice__remove {
  color: rgba(255, 255, 255, 0.5);
  font-weight: bold;
  padding-right: 8px;
  margin-top: -1px;
  padding-left: 10px;
  padding-bottom: 5px;
  font-size: 22px;
  background-color: transparent;
  border: none;
  float: left;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
}
.select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__choice__remove:hover {
  color: var(--white);
}
.select2-container--bootstrap4
  .select2-selection--multiple
  .select2-selection__clear {
  float: none;
  margin-right: 0;
  position: absolute !important;
  top: 0;
  right: 0;
  display: none;
}

.select2-container--bootstrap4 .select2-selection__clear {
  float: none;
  margin-right: 0;
  position: absolute !important;
  top: -2px;
  right: 30px;
  width: 20px;
  height: 20px;
  margin-top: 0;
  margin-right: 0;
  font-size: 24px;
  line-height: 0.9;
  background-color: transparent !important;
  color: var(--paragraph) !important;
  opacity: 0.7;
  border: none;
  transition: all 0.2s ease-in-out;
}
.select2-container--bootstrap4 .select2-selection__clear:hover {
  color: var(--blue) !important;
  opacity: 1;
}
.input-select-dark-version
  + .select2-container--bootstrap4
  .select2-selection__clear {
  color: var(--white) !important;
}

.select2-container {
  display: block;
}
.select2-container *:focus {
  outline: 0;
  box-shadow: none;
}

.input-group .select2-container--bootstrap4 {
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
}

.input-group-prepend ~ .select2-container--bootstrap4 .select2-selection {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.input-group
  > .select2-container--bootstrap4:not(:last-child)
  .select2-selection {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.select2-container--bootstrap4 .select2-selection {
  background-color: var(--blue-light);
  border: none;
  border-radius: 2px;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  outline: none;
  color: var(--blue-dark-2);
  outline: none;
  padding: 13px 20px;
  padding-right: 12px;
  padding-left: 10px;
  width: 100% !important;
  display: block;
}

.height-40-style .select2-container--bootstrap4 .select2-selection {
  padding: 8px 10px;
  height: 40px;
  padding-top: 9px;
  font-size: 14px;
}

.select-white .select2-container--bootstrap4 .select2-selection {
  border-radius: 0 !important;
  background-color: var(--white) !important;
}
.min-width-370 .select2-container--bootstrap4 .select2-selection {
  width: 370px !important;
  max-width: 100%;
}
.min-width-450 .select2-container--bootstrap4 .select2-selection {
  width: 450px !important;
  max-width: 100%;
}
.height-40-style .select2-container--bootstrap4 .select2-selection__clear {
  top: -7px;
  opacity: 0.6;
}

.light-style .select2-selection__placeholder {
  opacity: 0.7 !important;
  font-style: italic !important;
  font-size: 14px;
  font-weight: 400;
}
.light-style
  .select2-container--bootstrap4
  .select2-selection--single
  .select2-selection__arrow
  b {
  opacity: 0.5;
}

.input-select-dark-version + .select2-container--bootstrap4 .select2-selection {
  background-color: var(--dark);
  color: rgba(255, 255, 255, 0.7);
}

@media (prefers-reduced-motion: reduce) {
  .select2-container--bootstrap4 .select2-selection {
    -webkit-transition: none;
    transition: none;
  }
}

.select2-container--bootstrap4.select2-container--focus .select2-selection {
  border: none;
  box-shadow: none;
}

.select2-container--bootstrap4.select2-container--focus.select2-container--open
  .select2-selection {
  border: none;
}

.select2-container--bootstrap4.select2-container--disabled .select2-selection,
.select2-container--bootstrap4.select2-container--disabled.select2-container--focus
  .select2-selection {
  background-color: #e9ecef;
  cursor: not-allowed;
  border: none;
  -webkit-box-shadow: none;
  box-shadow: none;
}

.select2-container--bootstrap4.select2-container--disabled
  .select2-search__field,
.select2-container--bootstrap4.select2-container--disabled.select2-container--focus
  .select2-search__field {
  background-color: transparent;
}

select.is-invalid ~ .select2-container--bootstrap4 .select2-selection,
form.was-validated
  select:invalid
  ~ .select2-container--bootstrap4
  .select2-selection {
  border: none;
}

select.is-valid ~ .select2-container--bootstrap4 .select2-selection,
form.was-validated
  select:valid
  ~ .select2-container--bootstrap4
  .select2-selection {
  border: none;
}

.select2-container--bootstrap4 .select2-dropdown {
  margin-top: 2px;
  border: none;
  border-radius: 2px !important;
  z-index: 1121060;
  box-shadow: 0 12px 24px -4px rgba(27, 60, 141, 0.2);
}

.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--below {
  border-top: none;
  border-bottom-right-radius: 2px;
  border-bottom-left-radius: 2px;
}
.select2-container--bootstrap4 .select2-dropdown.select2-dropdown--above {
  border: none;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}
.select2-container--bootstrap4 .select2-dropdown .select2-results__option {
  font-size: 14px;
  line-height: 20px;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 20px;
  padding-right: 15px;
  text-align: left;
  transition: all 0.2s ease-in-out;
}

.select2-container--bootstrap4
  .select2-dropdown
  .select2-results__option[aria-selected="true"] {
  background-color: var(--blue-light);
  transition: all 0.2s ease-in-out;
}
.select2-container--bootstrap4
  .select2-dropdown.dark-version-style
  .select2-results__option[aria-selected="true"] {
  background-color: var(--blue);
}

.select2-container--bootstrap4 .select2-results__option--highlighted,
.select2-container--bootstrap4
  .select2-results__option--highlighted.select2-results__option[aria-selected="true"] {
  background-color: var(--blue);
  color: var(--white);
}

.select2-container--bootstrap4 .select2-results__option[role="group"] {
  padding: 0;
}

.select2-results__group {
  padding-left: 20px !important;
  font-size: 14px !important;
  color: var(--dark) !important;
  padding-top: 12px !important;
}
.select2-container--bootstrap4
  .select2-results__option[role="group"]
  .select2-results__options--nested
  .select2-results__option {
  padding-left: 30px;
}

.select2-container--bootstrap4 .select2-results > .select2-results__options {
  max-height: 15em;
  overflow-y: auto;
}
.select2-container--bootstrap4
  .select2-results
  > .select2-results__options::-webkit-scrollbar {
  width: 5px;
}
.select2-container--bootstrap4
  .select2-results
  > .select2-results__options::-webkit-scrollbar-track {
  background-color: var(--blue-dark);
}
.select2-container--bootstrap4
  .select2-results
  > .select2-results__options::-webkit-scrollbar-thumb {
  background-color: var(--blue-2);
}
.select2-container--bootstrap4
  .select2-results
  > .select2-results__options::-webkit-scrollbar-thumb:hover {
  background-color: var(--blue);
}

.select2-container--bootstrap4 .select2-results__group {
  padding: 6px;
  display: list-item;
  color: #6c757d;
}

.select2-container--bootstrap4 .select2-selection__clear {
  width: 0.9em;
  height: 0.9em;
  line-height: 0.75em;
  padding-left: 0.15em;
  margin-top: 0.7em;
  border-radius: 100%;
  background-color: #c8c8c8;
  color: #f8f9fa;
  float: right;
  margin-right: 0.1em;
}
.select2-container--bootstrap4 .select2-selection__clear:hover {
  background-color: #afafaf;
}

.search-boxes {
  border-radius: 2px;
  border: 3px solid var(--blue-light);
  padding: 5px;
}

h6.search-heading-active-location,
.search-boxes-active-location {
  opacity: 1;
  pointer-events: auto;
  transition: all 200ms linear;
}
.search-boxes-active-checkbox:not(:checked)
  ~ .search-boxes-active-checkbox-2:not(:checked)
  ~ .search-boxes-active-location {
  opacity: 0.2;
  pointer-events: none;
}
.search-boxes-active-checkbox:not(:checked)
  ~ .search-boxes-active-checkbox-2:not(:checked)
  ~ h6.search-heading-active-location {
  opacity: 0.2;
  pointer-events: none;
}

/* #Login
================================================== */

.img-background-cover {
  background-size: cover;
  background-position: center;
}

.log-in-section {
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 13px 33px rgba(64, 66, 85, 0.07);
  border-radius: 4px;
}
.log-in-section img {
  width: 140px;
  height: auto;
  display: block;
}

/* #Dashboard
================================================== */
.metric-name {
  margin-bottom: 0;
  font-size: 0.9rem;
  line-height: 2.01667rem;
  display: block;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-wrap: normal;
}
.filter-button {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: all 200ms linear;
  padding: 0;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: var(--blue-dark-2);
  margin-top: 6px;
}
.filter-button:hover {
  opacity: 0.7;
}
.filter-button img {
  width: 16px;
  height: 16px;
  display: block;
}
.filter-button span.close-text {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 2;
  opacity: 0;
  pointer-events: none;
  left: -19px;
  transition: all 200ms linear;
}
.filter-button span.scnd-text {
  padding-left: 0;
  transition: all 200ms linear;
}
body.opened-filter .filter-button span.close-text {
  opacity: 1;
  pointer-events: auto;
  left: 21px;
}
body.opened-filter .filter-button span.scnd-text {
  padding-left: 40px;
}
.filter-options {
  opacity: 0;
  pointer-events: none;
  transition: all 200ms linear;
}
body.opened-filter .filter-options {
  opacity: 1;
  pointer-events: auto;
}
.select-location {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  transition: all 200ms linear;
  padding: 0;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  border: none;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: var(--blue-dark-2);
  background-color: var(--white);
  height: 32px;
}
.select-location.full {
  height: 40px;
  width: 100%;
  -ms-flex-pack: justify !important;
  justify-content: space-between !important;
  font-weight: 400;
  font-style: italic;
  color: rgba(31, 32, 41, 0.5);
}
.select-location span {
  padding: 0 10px;
}
.select-location.full span {
  padding: 0 15px;
}
.select-location img {
  width: 10px;
  height: 10px;
  display: block;
  margin: 11px 0;
  transition: all 200ms linear;
}
.select-location.full img {
  width: 14px;
  height: 14px;
  opacity: 0.6;
  margin-top: 14px;
  margin-right: 1px;
}
.select-location span:nth-child(2) {
  border-left: 1px solid rgba(64, 66, 85, 0.15);
  height: 100%;
}
.select-location.full span:nth-child(2) {
  border-left: none;
}

.dropdown-select-locations {
  border-radius: 0;
  min-width: 390px;
  max-width: 100%;
  padding: 15px;
  padding-bottom: 20px;
  margin-top: 5px;
}
.select-location[aria-expanded="true"] img {
  transform: rotate(180deg);
}

.form-group input[type="checkbox"] {
  position: absolute;
  visibility: hidden;
}
.checkbox {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400 !important;
  color: var(--blue-dark-2);
  display: block;
}
.checkbox.left-35 {
  padding-left: 35px;
}
.checkbox:after {
  position: absolute;
  content: "";
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 0;
  border: 1px solid rgba(64, 66, 85, 0.19);
  box-sizing: border-box;
  background-color: var(--white);
  transition: all 200ms linear;
}
.checkbox.checkbox-gray:after {
  background-color: var(--blue-light);
  border-color: var(--blue-light);
}
input[type="checkbox"]:checked + .checkbox:after {
  border-color: var(--blue);
  background-color: var(--blue);
}
.checkbox:before {
  position: absolute;
  content: "";
  opacity: 0;
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
  text-align: center;
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./img/check.svg");
  z-index: 2;
  transition: all 200ms linear;
}
input[type="checkbox"]:checked + .checkbox:before {
  opacity: 1;
}

.customize-button {
  position: relative;
  cursor: pointer;
  overflow: hidden;
  display: block;
  border: none;
  transition: all 200ms linear;
}
.customize-button img {
  width: 22px;
  height: auto;
  display: block;
}
.customize-button:hover {
  opacity: 0.7;
}
.dropdown-customize {
  min-width: 115px;
}

.modal-metric .modal-content {
  max-width: 520px;
}
.modal-metric .modal-dialog {
  max-width: 520px;
}
.modal-metric .modal-body {
  padding: 25px 10px;
}

.modal-metric.bigger .modal-content {
  max-width: 550px;
}
.modal-metric.bigger .modal-dialog {
  max-width: 550px;
}

.form-group input[type="radio"] {
  position: absolute;
  visibility: hidden;
}
.radio {
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400 !important;
  color: var(--blue-dark-2);
}
.radio:after {
  position: absolute;
  content: "";
  top: -1px;
  left: 0;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  border: 1px solid rgba(64, 66, 85, 0.19);
  box-sizing: border-box;
  background-color: var(--white);
  transition: all 200ms linear;
}
.radio.radio-gray:after {
  background-color: var(--blue-light);
  border-color: var(--blue-light);
}
.radio:before {
  position: absolute;
  content: "";
  opacity: 0;
  top: 4px;
  left: 5px;
  width: 12px;
  height: 12px;
  text-align: center;
  border-radius: 50%;
  z-index: 2;
  background-color: var(--blue);
  transition: all 200ms linear;
}
input[type="radio"]:checked + .radio:before {
  opacity: 1;
}

.pt-fix-12 {
  padding-top: 12px;
}

.form-style.number-box {
  padding: 13px 2px;
  padding-left: 15px;
  text-align: center !important;
  height: 50px;
  width: 55px;
  font-weight: 500;
  border-radius: 2px;
  font-size: 18px;
  line-height: 24px;
}
.q-mark {
  position: absolute;
  z-index: 5;
  top: 2px;
  right: 0;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: var(--blue-dark-2);
  line-height: 18px;
  font-size: 11px;
  color: var(--white);
  font-weight: 700;
  text-align: center;
  transition: all 200ms linear;
}
.q-mark:hover {
  background-color: var(--black);
}

.q-mark.relative-pos {
  position: relative;
  top: auto;
  right: auto;
}

.report-button {
  cursor: pointer;
  color: var(--blue-sec);
}

.form-group-half {
  width: 50%; /* Adjust the width as needed */
  /* Other styles for the form-group */
}

.form-group-fourthy {
  width: 40%; /* Adjust the width as needed */
  /* Other styles for the form-group */
}

/* #Charts
================================================== */

.highcharts-figure {
  margin-bottom: 0;
}
.highcharts-exporting-group {
  display: none;
}
.highcharts-tooltip > span {
  padding: 10px !important;
  color: #fff !important;
  font-family: "Roboto", sans-serif !important;
  font-weight: 400 !important;
  font-size: 18px !important;
  line-height: 26px !important;
}
.after-h-text {
  font-size: 13px !important;
}
.highcharts-tooltip > span span {
  font-size: 14px !important;
}
.highcharts-tooltip > span b {
  font-size: 19px !important;
}
.highcharts-credits {
  display: none;
}
.highcharts-loading-inner {
  color: #000000;
}

.home-d-box {
  border-radius: 4px;
  transition: all 200ms linear;
}
.home-d-box h6,
.home-d-box p,
.home-d-box .q-mark,
.home-d-box figure {
  transition: all 200ms linear;
}
body.home-d-box-edit .home-d-box {
  box-shadow: 0 0 4px #88cefb;
  cursor: move;
}
body.home-d-box-edit .home-d-box:active {
  box-shadow: 0 0 7px #88cefb;
  cursor: grabbing;
  cursor: -moz-grabbing;
  cursor: -webkit-grabbing;
}
body.home-d-box-edit .home-d-box h6,
body.home-d-box-edit .home-d-box p,
body.home-d-box-edit .home-d-box .q-mark {
  opacity: 0;
  pointer-events: none;
}
body.home-d-box-edit .home-d-box figure {
  opacity: 0.1;
  pointer-events: none;
}
.hide-on-edit {
  transition: all 200ms linear;
}
body.home-d-box-edit .hide-on-edit {
  opacity: 0;
  pointer-events: none;
}

.edit-remove-links {
  position: absolute;
  z-index: 22;
  top: 12px;
  right: 0;
  width: 100%;
  text-align: right;
  opacity: 0;
  pointer-events: none;
  transition: all 200ms linear;
}
body.home-d-box-edit .edit-remove-links {
  opacity: 1;
  pointer-events: auto;
}
.edit-remove-links p {
  opacity: 0;
  pointer-events: none;
  transition: all 200ms linear;
}
body.home-d-box-edit .edit-remove-links p {
  opacity: 1;
  pointer-events: auto;
}

.edit-buttons-wrap {
  position: absolute;
  z-index: 22;
  bottom: -5px;
  right: 15px;
  min-width: 200px;
  text-align: right;
  opacity: 0;
  pointer-events: none;
  transform: translateX(-20px);
  transition: all 200ms linear;
}

body.home-d-box-edit .edit-buttons-wrap {
  opacity: 1;
  pointer-events: auto;
  transform: translateX(0);
  transition-delay: 200ms;
}

.disable-metric-select {
  opacity: 0.3;
  pointer-events: none;
}

.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
}
.gu-hide {
  display: none !important;
}
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/* #Avatar/Profile image upload
================================================== */

.avatar-upload {
  position: relative;
  padding: 0;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.container-avatar {
  height: 106px;
  border: 2px dashed var(--blue-2);
  border-radius: 50%;
  position: relative;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  width: 106px;
  margin: 0;
  padding: 0;
  margin-right: 15px;
}
.container-avatar.corporate {
  height: 106px;
  border: 2px dashed var(--blue-2);
  border-radius: 0;
  max-width: auto;
  min-width: 256px;
  display: block;
}
.container-avatar .imageWrapper {
  margin-top: 3px;
  width: 96px;
  padding-bottom: 96px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.container-avatar.corporate .imageWrapper {
  margin: 0 auto;
  text-align: center;
  margin-top: 8px;
  height: 86px;
  border-radius: 0;
  padding-bottom: 0;
  overflow: hidden;
  position: relative;
  display: block;
  min-width: 246px;
  top: auto;
  left: auto;
  transform: translateX(0);
}
.container-avatar .imageWrapper img {
  height: 96px;
  width: initial;
  max-height: 100%;
  max-width: initial;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.container-avatar.corporate .imageWrapper img {
  height: 100%;
  width: auto;
  display: block;
  max-width: auto;
  position: relative;
  margin: 0 auto;
  text-align: center;
  top: auto;
  left: auto;
  transform: translate(0, 0);
}
.file-upload {
  position: relative;
  overflow: hidden;
  margin: 10px 0;
  max-width: 100%;
  text-align: center;
  color: var(--white);
  background-color: var(--blue);
  height: 36px;
  font-size: 15px;
  line-height: 22.5px;
  letter-spacing: 0.2px;
  font-weight: 500;
  border-radius: 0;
  border: none;
  padding: 0;
  letter-spacing: 0;
  -ms-transition: all 0.2s ease;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  width: 130px;
}
.file-upload:active,
.file-upload:focus {
  box-shadow: none;
  border: none;
  outline: none;
}
.file-upload:hover {
  color: var(--white);
  background-color: var(--blue-sec);
  box-shadow: 0 12px 24px 0 rgba(25, 163, 252, 0.25);
}
.file-upload input.file-input {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0;
  padding: 0;
  font-size: 14px;
  cursor: pointer;
  opacity: 0;
  filter: alpha(opacity=0);
  height: 100%;
}

.card,
.card-header,
.card-body {
  padding: 0;
  margin: 0;
  border-radius: 0;
  border: none;
  background-color: transparent;
}

.accordion-link {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 22px;
  color: var(--blue-dark-2);
  padding: 0;
  background-color: transparent;
  cursor: pointer;
  padding-right: 35px;
  transition: all 200ms linear;
}
.accordion-link:hover {
  text-decoration: none;
  background-color: transparent;
  color: var(--blue-dark-2);
  opacity: 0.7;
}
.accordion-link:before,
.accordion-link:after {
  position: absolute;
  z-index: 2;
  content: "";
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./img/plus.svg");
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  transition: all 200ms linear;
}
.accordion-link:after {
  background-image: url("./img/minus.svg");
  opacity: 0;
}
.accordion-link.download:before,
.accordion-link.download:after {
  position: absolute;
  z-index: 2;
  content: "";
  background-size: 18px 18px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./img/download.svg");
  top: 0;
  right: 0;
  width: 22px;
  height: 22px;
  transition: all 200ms linear;
}
.accordion-link.download:after {
  background-image: url("./img/download.svg");
  opacity: 0;
}
.accordion-link[aria-expanded="true"]:after {
  opacity: 1;
}
.accordion-link[aria-expanded="true"]:before {
  opacity: 0;
}

.accordion-link.sub-menu {
  font-size: 15px;
  padding-right: 25px;
  padding-left: 15px;
  margin-right: 15px;
  font-style: italic;
  display: -webkit-box;
  display: flex;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}
.accordion-link.sub-menu :before,
.accordion-link.sub-menu :after {
  background-size: 16px 16px;
}

.submenu-sub-accordion .accordion-link.sub-menu {
  padding-left: 25px;
}

/* #Reports page
================================================== */

.pin-button {
  position: relative;
  height: 32px;
  width: 32px;
  background-image: url("./img/pin.svg");
  background-size: 22px 22px;
  background-repeat: no-repeat;
  background-position: center;
  background-color: var(--white);
  display: block;
  border-radius: 2px;
  transition: all 200ms linear;
}
.pin-button:hover {
  background-color: var(--blue-dark-2);
  background-image: url("./img/pin-light.svg");
}

.form-style.daterange-style {
  padding: 7px 15px;
  text-align: left;
  height: 32px;
  width: 222px;
  max-width: 100%;
  border-radius: 2px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 400;
  color: var(--blue-dark-2);
  background-color: var(--white);
  cursor: pointer;
}

.daterangepicker:before,
.daterangepicker:after {
  display: none;
}
.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 0;
  border: none;
  max-width: none;
  padding: 0;
  margin-top: 5px;
  top: 100px;
  left: 20px;
  z-index: 3001;

  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  box-shadow: 0px 12px 24px rgba(64, 66, 85, 0.08);

  transition: all 200ms linear;
  /* opacity: 0; */
  /* pointer-events: none; */
  transform: translateY(10px);

  /*
    
*/
}
body.opened-date-picker .daterangepicker {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}
.daterangepicker .drp-calendar {
  padding-top: 15px !important;
  padding-bottom: 25px !important;
}
.daterangepicker .drp-calendar.left {
  padding-left: 25px !important;
}
.daterangepicker .drp-calendar.right {
  padding-right: 25px !important;
}
.daterangepicker .calendar-table .next span,
.daterangepicker .calendar-table .prev span {
  opacity: 0.6;
}
.daterangepicker .calendar-table .next:hover,
.daterangepicker .calendar-table .prev:hover {
  background-color: transparent !important;
}
.daterangepicker .calendar-table .next:hover span,
.daterangepicker .calendar-table .prev:hover span {
  border-color: var(--blue);
  opacity: 1;
}
.daterangepicker .calendar-table th,
.daterangepicker .calendar-table td {
  border-radius: 0 !important;
}
.daterangepicker td.in-range {
  background-color: var(--blue-light);
  color: var(--blue-dark-2);
}
.daterangepicker td.active,
.daterangepicker td.active:hover {
  background-color: var(--blue);
}
.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
  background-color: var(--blue-dark-3);
  color: var(--white);
}
.daterangepicker td.end-date {
  color: var(--white);
}

.daterangepicker .ranges {
  float: none !important;
  display: block !important;
  width: 100% !important;
  margin-top: 25px !important;
}
.daterangepicker .ranges ul {
  padding: 0;
  width: 100% !important;
  margin: 0 auto;
  text-align: center;
  display: block im !important;
}
.daterangepicker .ranges li {
  display: inline-block;
  font-size: 12px;
  padding: 0;
  cursor: pointer;
  margin: 0 auto;
  font-weight: 500;
  text-align: center;
  margin-left: 8px;
  margin-right: 8px;
  color: var(--blue);
  transition: all 200ms linear;
}
.daterangepicker .ranges li:last-child {
  display: none;
}
.daterangepicker .ranges li:hover {
  background-color: transparent;
  color: var(--blue-2);
}
.daterangepicker .ranges li.active {
  background-color: transparent;
  color: var(--blue);
}
.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: none;
}

.form-style.daterange-style + label {
  position: absolute;
  top: 0;
  right: 0;
  width: 32px;
  height: 100%;
  z-index: 10;
  background-color: var(--white);
  border-left: 1px solid rgba(64, 66, 85, 0.15);
  padding: 0 11px;
  cursor: pointer;
}
.form-style.daterange-style + label img {
  width: 10px;
  height: 10px;
  display: block;
  margin: 11px 0;
  transition: all 200ms linear;
}
body.opened-date-picker .form-style.daterange-style + label img {
  transform: rotate(180deg);
}

.link-line {
  position: relative;
  display: inline-block;
  cursor: pointer;
  color: rgba(64, 66, 85, 0.7);
}
.link-line:hover {
  color: var(--blue-dark-2);
}
.link-line.active {
  color: var(--blue-dark-2);
}
.link-line:before {
  position: absolute;
  display: block;
  background-color: var(--blue);
  bottom: -2px;
  left: 0;
  width: 0;
  height: 2px;
  content: "";
  transition: all 200ms linear;
}
.link-line:hover:before,
.link-line.active:before {
  width: 100%;
}

.chart-report-height {
  height: 320px;
  width: 100%;
}

.highcharts-axis-labels text {
  color: rgba(64, 66, 85, 0.5) !important;
}

.padding-facts {
  padding-top: 15px;
  padding-bottom: 13px;
}

.link-download {
  cursor: pointer;
  color: var(--blue-dark-2);
}
.link-download:hover {
  opacity: 0.7;
  color: var(--blue-dark-2);
}
.link-download img {
  width: 14px;
  height: 14px;
  display: inline-block;
}

.blue-box-table {
  position: relative;
  width: 100%;
  background: #def2ff;
  border-left: 1px solid var(--white);
  padding: 0 15px;
  height: 38px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  transition: all 200ms linear;
  white-space: nowrap;
}
.white-box-table {
  position: relative;
  width: 100%;
  box-shadow: 1px 0px 0px rgba(64, 66, 85, 0.07);
  padding: 0 15px;
  height: 40px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  border-bottom: 1px solid rgba(64, 66, 85, 0.07);
  transition: all 200ms linear;
}
.white-box-table.border-none {
  border: none;
}
.box-center-text {
  -webkit-justify-content: center;
  -moz-justify-content: center;
  -ms-justify-content: center;
  justify-content: center;
  -ms-flex-pack: center;
  text-align: center;
}
.box-center-text.selected {
  background-color: rgb(72, 75, 94);
  /* background-color: #1A2430 !important;  */
}
.box-center-text.selected p {
  color: var(--white) !important;
}

.tab-reports-col p {
  transition: all 200ms linear;
}
.tab-reports-col:hover p.date-top {
  color: var(--white);
}

.tab-reports-col:hover .blue-box-table {
  background-color: var(--blue-dark-2);
}
.tab-reports-col:hover .white-box-table {
  background-color: var(--blue-light);
}
.tab-reports-col.selected .white-box-table {
  background-color: var(--blue-light);
}

.tab-reports-col {
  /* float: left; */
  display: block;
  position: relative;
}

.section-table-scroll-wrapper {
  overflow-x: auto;
  width: 100%;
}
.section-table-scroll-wrap {
  width: 100%;
  overflow-x: scroll;
  white-space: nowrap;
}
.over-p p {
  display: -webkit-box;
  display: flex;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}

/* #Chats
================================================== */

.tab-box-blue {
  position: relative;
  width: 100%;
  background-color: #def2ff;
  border-right: 1px solid var(--white);
  padding: 0 15px;
  height: 38px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  transition: all 200ms linear;
}
.tab-box-white {
  position: relative;
  width: 100%;
  border-right: 1px solid rgba(64, 66, 85, 0.07);
  padding: 0 15px;
  height: 50px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  transition: all 200ms linear;
}
.no-tab-box-border {
  border: none;
}
.chat-archive-table p {
  display: -webkit-box;
  display: flex;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
  box-orient: vertical;
  overflow: hidden;
}

.chat-archive-table .tab-box-white p:nth-child(2) {
  min-width: 120px;
}

.tab-ch-all-sec {
  border-bottom: 1px solid rgba(64, 66, 85, 0.07);
}

.pag-tab-links {
  position: relative;
  display: block;
  margin: 0;
  padding: 0;
  list-style: none;
}
.pag-tab-links li {
  position: relative;
  display: inline-block;
  margin: 0 3px;
  padding: 0;
  list-style: none;
}
.pag-tab-links li a {
  position: relative;
  display: block;
  margin: 0;
  padding: 0 12px;
  list-style: none;
  min-width: 32px;
  height: 32px;
  background-color: var(--white);
  color: var(--blue-dark-3);
  font-size: 13px;
  line-height: 32px;
  text-transform: uppercase;
  text-align: center;
}
.pag-tab-links li.active a,
.pag-tab-links li a:hover {
  background-color: var(--blue);
  color: var(--white);
}
.pag-tab-links li.disabled a {
  pointer-events: none;
  color: rgba(64, 66, 85, 0.3);
}

.chat-view-wrapper {
  position: relative;
  width: 100%;
  display: block;
  border-top: 1px solid rgba(64, 66, 85, 0.1);
  border-bottom: 1px solid rgba(64, 66, 85, 0.1);
  padding: 15px 0;
}
.chat-view-wrap {
  position: relative;
  width: 100%;
  display: block;
  height: 365px;
  overflow: hidden;
  overflow-y: auto;
}
.blue-chat {
  max-width: 300px;
  padding: 10px 15px;
  border-radius: 8px;
  border-top-right-radius: 0;
  background-color: var(--blue);
}
.blue-chat p {
  color: var(--white);
}
.gray-chat {
  max-width: 300px;
  padding: 10px 15px;
  border-radius: 8px;
  border-top-left-radius: 0;
  background-color: var(--blue-light);
}
.chat-img img {
  border-radius: 50%;
  width: 36px;
  height: auto;
  display: block;
}

.focus-keyword {
  background-color: var(--warning);
  color: var(--blue-dark-4);
  font-weight: 500;
  padding: 3px 5px;
}

/* #Scrollbar
================================================== */

.ps {
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
}
.ps__rail-x {
  display: none;
  opacity: 1;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  height: 15px;
  /* there must be 'bottom' or 'top' for ps__rail-x */
  bottom: 0px;
  /* please don't change 'position' */
  position: absolute;
}
.ps__rail-y {
  display: none;
  opacity: 1;
  transition: background-color 0.2s linear, opacity 0.2s linear;
  -webkit-transition: background-color 0.2s linear, opacity 0.2s linear;
  width: 15px;
  /* there must be 'right' or 'left' for ps__rail-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
}
.ps--active-x > .ps__rail-x,
.ps--active-y > .ps__rail-y {
  display: block;
  background-color: transparent;
}
.ps:hover > .ps__rail-x,
.ps:hover > .ps__rail-y,
.ps--focus > .ps__rail-x,
.ps--focus > .ps__rail-y,
.ps--scrolling-x > .ps__rail-x,
.ps--scrolling-y > .ps__rail-y {
  opacity: 1;
}
.ps .ps__rail-x:hover,
.ps .ps__rail-y:hover,
.ps .ps__rail-x:focus,
.ps .ps__rail-y:focus,
.ps .ps__rail-x.ps--clicking,
.ps .ps__rail-y.ps--clicking {
  background-color: transparent;
  opacity: 1;
}
.ps__thumb-x {
  display: none;
  background-color: rgba(64, 66, 85, 0.25);
  border-radius: 1px;
  transition: background-color 0.2s linear, height 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, height 0.2s ease-in-out;
  height: 4px;
  /* there must be 'bottom' for ps__thumb-x */
  bottom: 2px;
  /* please don't change 'position' */
  position: absolute;
  opacity: 1;
}
.ps__thumb-y {
  background-color: rgba(64, 66, 85, 0.25);
  border-radius: 1px;
  transition: background-color 0.2s linear, width 0.2s ease-in-out;
  -webkit-transition: background-color 0.2s linear, width 0.2s ease-in-out;
  width: 4px;
  /* there must be 'right' for ps__thumb-y */
  right: 0;
  /* please don't change 'position' */
  position: absolute;
  opacity: 1;
}
.ps__rail-x:hover > .ps__thumb-x,
.ps__rail-x:focus > .ps__thumb-x,
.ps__rail-x.ps--clicking .ps__thumb-x {
  background-color: var(--blue);
  height: 4px;
  opacity: 1;
}
.ps__rail-y:hover > .ps__thumb-y,
.ps__rail-y:focus > .ps__thumb-y,
.ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: var(--blue);
  width: 4px;
  opacity: 1;
}
@supports (-ms-overflow-style: none) {
  .ps {
    overflow: auto !important;
  }
}
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .ps {
    overflow: auto !important;
  }
}

/* #Adding emails in profile
================================================== */

.tags-section {
  border-radius: 0;
  box-shadow: none;
  padding: 10px;
  padding-bottom: 0;
  background-color: var(--blue-light);
  margin: 0;
  width: 100%;
  display: block;
}
label.for-tags-typer {
  display: block;
  margin-bottom: 0;
  width: 100%;
}
.tag-typer {
  outline: none;
  border: none;
  margin-top: 5px;
  height: 40px;
  font-size: 14px;
  font-weight: 400;
  font-style: italic;
}
.tags-section input::placeholder {
  color: var(--blue-dark);
  opacity: 0.5;
}
.tags-section input:-ms-input-placeholder {
  color: var(--blue-dark);
  opacity: 0.5;
}
.tags-section input::-moz-placeholder {
  color: var(--blue-dark);
  opacity: 0.5;
}
.tags-section input:-moz-placeholder {
  color: var(--blue-dark);
  opacity: 0.5;
}
.tags-section input:-webkit-input-placeholder {
  color: var(--blue-dark);
  opacity: 0.5;
}
.tag-button {
  display: inline-block;
  background-color: var(--blue);
  border-radius: 0;
  color: var(--white);
  padding: 0 10px;
  padding-left: 30px;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 31px;
  line-height: 31px;
  font-size: 15px;
  font-weight: 500;
  position: relative;
  cursor: default;
  padding-top: 1px;
  padding-right: 12px;
  -webkit-transform-origin: 0% 50%;
  -webkit-animation: swing 1s;
  -o-animation: swing 1s;
  animation: swing 1s;
}
.tag-button .close-button {
  position: absolute;
  background: inherit;
  cursor: pointer;
  background-color: transparent;
  height: 31px;
  line-height: 31px;
  font-size: 20px;
  left: 10px;
  z-index: 3;
  color: var(--white);
  opacity: 0.5;
  padding-top: 1px;
  transition: all 200ms linear;
}
.close-button:hover {
  opacity: 1;
}
@-webkit-keyframes swing {
  0% {
    -webkit-transform: rotate(100deg);
  }
  25% {
    -webkit-transform: rotate(-25deg);
  }
  50% {
    -webkit-transform: rotate(15deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}
@keyframes swing {
  0% {
    -webkit-transform: rotate(-200);
  }
  25% {
    -webkit-transform: rotate(-70);
  }
  50% {
    -webkit-transform: rotate(-185);
  }
  100% {
    -webkit-transform: rotate(-180);
  }
}

/* #Profile pages
================================================== */

.profile-box-blue {
  position: relative;
  padding: 0 15px;
  height: 38px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 100%;
}
.profile-box-blue-bor-right {
  border-right: 1px solid var(--white);
}
.profile-box-blue .justify-content-center {
  padding: 0;
}

.profile-category-wrap {
  background-color: var(--white);
  border-top: 1px solid rgba(64, 66, 85, 0.06);
  transition: all 200ms linear;
  cursor: move;
}
.row-sec-border-top {
  border-top: 1px solid rgba(64, 66, 85, 0.06);
}
.profile-category-wrap:hover {
  background-color: #ecf8ff;
}
.profile-category-wrap:hover .profile-box-white-bor-right {
  border-color: var(--white);
}

.profile-box-white {
  position: relative;
  padding: 0 15px;
  min-height: 70px;
  display: -webkit-inline-flex;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-align-items: center;
  -moz-align-items: center;
  -ms-align-items: center;
  align-items: center;
  width: 100%;
}
.profile-box-white.box-min-h-100 {
  min-height: 100px;
  padding-top: 10px;
  padding-bottom: 10px;
}
.profile-box-white-bor-right {
  border-right: 1px solid rgba(64, 66, 85, 0.06);
  transition: all 200ms linear;
}

.btn-red {
  background-color: var(--danger);
  color: var(--white);
}
.btn-red:hover {
  background-color: var(--blue-dark);
  color: var(--white);
}
.btn-box-36 {
  width: 36px;
  height: 36px;
  padding: 0;
}
.btn-box-36 img {
  width: 16px;
  height: 16px;
  display: block;
}

.profile-select-option {
  opacity: 0;
  pointer-events: none;
  transform: translateY(10px);
  transition: all 200ms linear;
}
body.show-category-det .profile-select-option {
  opacity: 1;
  pointer-events: auto;
  transform: translateY(0);
}

.link-back-img img {
  width: 12px;
  height: 12px;
  display: inline-block;
}

/* #Rich text editor
================================================== */

.editor-full {
  border-radius: 0;
}
.ql-snow.ql-toolbar {
  border: none;
  background-color: #edeaf8;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding: 10px;
}
.ql-snow.ql-container {
  border: none;
  background-color: var(--blue-light);
  padding: 0;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.ql-formats .dropdown-menu,
.ql-size .dropdown-menu,
.ql-header .dropdown-menu {
  background-color: var(--white);
}
.ql-picker-options {
  padding: 10px 20px !important;
  transform: translate(0, 15px) !important;
  visibility: hidden !important;
  opacity: 0 !important;
  border: none !important;
  box-shadow: 0 12px 24px 0 rgba(27, 60, 141, 0.15) !important;
  display: block !important;
  pointer-events: none !important;
  transition: all 200ms linear !important;
}
.ql-picker.ql-expanded .ql-picker-options {
  transform: translate(0, 0) !important;
  visibility: visible !important;
  opacity: 1 !important;
  pointer-events: auto !important;
}
.ql-color .ql-picker-options,
.ql-background .ql-picker-options {
  padding: 5px !important;
  padding-right: 0 !important;
}
.ql-align .ql-picker-options {
  padding: 5px !important;
}
.ql-snow .ql-picker:active,
.ql-snow .ql-picker:focus {
  outline: none !important;
}
.ql-toolbar.ql-snow .ql-picker.ql-expanded .ql-picker-label {
  outline: none !important;
  padding: 3px !important;
  border: none !important;
}
.ql-picker-label {
  transition: all 200ms linear;
  border: none !important;
}
.ql-picker-item {
  transition: all 200ms linear;
  border: none !important;
}
.ql-picker-item:active,
.ql-picker-item:focus {
  outline: none !important;
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  font-size: 14px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  font-size: 21px;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  font-size: 28px;
}
.ql-editor .ql-size-small {
  font-size: 14px;
}
.ql-editor .ql-size-large {
  font-size: 21px;
}
.ql-editor .ql-size-huge {
  font-size: 28px;
}

.ql-snow .ql-editor blockquote {
  border-left: 4px solid var(--blue-2);
  font-size: 17px;
}
.ql-editor ol,
.ql-editor ul {
  padding-left: 20px;
  font-size: 17px;
}
.ql-toolbar.ql-snow .ql-formats {
  margin-right: 15px;
}
.ql-toolbar.ql-snow {
  padding: 4px !important;
}
.ql-snow.ql-toolbar button,
.ql-snow .ql-toolbar button {
  height: 24px;
  padding: 3px 5px;
  width: 32px;
}

/* #Range Sliders
================================================== */

.irs {
  font-family: "Roboto", sans-serif !important;
  margin-top: 5px !important;
  transition: all 200ms linear !important;
}
.irs--flat .irs-line {
  top: 28px !important;
  height: 6px !important;
}
.irs--flat .irs-bar {
  top: 28px !important;
  height: 6px !important;
  background-color: var(--blue) !important;
}
.irs--flat .irs-handle > i:first-child {
  top: -2px !important;
  left: 50% !important;
  width: 20px !important;
  height: 20px !important;
  border-radius: 50% !important;
  margin-left: -10px !important;
  background-color: var(--white) !important;
  cursor: ew-resize !important;
  box-shadow: 0px 2px 6px rgba(24, 23, 23, 0.2) !important;
}
.irs--flat .irs-handle.state_hover > i:first-child,
.irs--flat .irs-handle:hover > i:first-child {
  background-color: var(--white) !important;
}
.irs--flat .irs-min,
.irs--flat .irs-max {
  padding: 2px 4px !important;
  color: var(--blue-dark) !important;
  border-radius: 1px !important;
}
.irs--flat .irs-from,
.irs--flat .irs-to,
.irs--flat .irs-single {
  padding: 3px 6px !important;
  background-color: var(--blue-dark) !important;
  font-size: 11px !important;
  font-weight: 700 !important;
  letter-spacing: 0.2px !important;
  border-radius: 3px !important;
  margin-top: -6px !important;
}
.irs--flat .irs-from:before,
.irs--flat .irs-to:before,
.irs--flat .irs-single:before {
  border-top-color: var(--blue-dark) !important;
}

.working-time-close:checked ~ .irs {
  opacity: 0.2 !important;
  pointer-events: none !important;
}
.workh-p-width {
  width: 90px !important;
}

.loc-sub-accordion {
  position: relative;
  display: inline-block;
  padding-left: 30px;
  cursor: pointer;
  line-height: 22px;
  font-size: 14px;
  font-weight: 400 !important;
  color: var(--blue-dark-2);
  transform: translateY(-3px);
}

.loc-sub-accordion:after,
.loc-sub-accordion:before {
  position: absolute;
  z-index: 2;
  content: "";
  background-size: 16px 16px;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("./img/plus.svg");
  top: -1px;
  left: -23px;
  width: 22px;
  height: 22px;
  transition: all 200ms linear;
}
.loc-sub-accordion:after {
  background-image: url("./img/minus.svg");
  opacity: 0;
}
.loc-sub-accordion[aria-expanded="true"]:after {
  opacity: 1;
}
.loc-sub-accordion[aria-expanded="true"]:before {
  opacity: 0;
}

/* #Colors and sizes
================================================== */

.color-green {
  color: var(--green);
}
.color-red {
  color: var(--red);
}
.color-blue {
  color: var(--blue);
}
.color-teal {
  color: var(--teal);
}
.color-yellow {
  color: var(--yellow);
}

.background-white {
  background-color: var(--white);
}
.background-blue-light {
  background-color: var(--blue-light);
}
.background-blue-light-2 {
  background-color: rgba(25, 163, 252, 0.1);
}
.background-blue-light-3 {
  background-color: rgba(136, 206, 251, 0.1);
}
.background-blue-light-4 {
  background-color: #def2ff;
}

.font-size-11 {
  font-size: 11px;
}
.font-size-12 {
  font-size: 12px;
}
.font-size-13 {
  font-size: 13px;
}
.font-size-14 {
  font-size: 14px;
}
.font-size-15 {
  font-size: 15px;
}
.font-size-16 {
  font-size: 16px;
}

.line-height-13 {
  line-height: 13px;
}
.line-height-14 {
  line-height: 14px;
}
.line-height-18 {
  line-height: 18px;
}
.line-height-22 {
  line-height: 22px;
}
.line-height-32 {
  line-height: 32px;
}

.opacity-50 {
  opacity: 0.5;
}
.opacity-70 {
  opacity: 0.7;
}

.border-0 {
  border-radius: 0;
}
.border-2 {
  border-radius: 2px;
}
.border-4 {
  border-radius: 4px;
}
.font-weight-400 {
  font-weight: 400;
}
.font-weight-500 {
  font-weight: 500;
}
.font-weight-700 {
  font-weight: 700;
}

.max-260 {
  max-width: 240px;
}
.max-450 {
  width: 450px;
  max-width: 100%;
}
.max-200 {
  width: 200px;
}
.max-140 {
  /* width: 140px;     */
  display: table-cell;
  white-space: normal;
  width: 1%;
}

/* #Media
================================================== */

@media (max-width: 1500px) {
}

@media (max-width: 1300px) {
}

@media (max-width: 1199px) {
  body.opened-sub-nav .sec-move-left {
    padding-left: 60px;
  }
  .profile-box-white {
    min-height: auto;
  }
  .profile-box-white-bor-right {
    border-color: transparent;
    height: auto;
    padding-bottom: 10px;
  }
  .profile-category-wrap {
    padding: 20px 5px;
  }
  .profile-category-wrap:hover .profile-box-white-bor-right {
    border-color: transparent;
  }
  .profile-box-white.box-min-h-100 {
    min-height: auto;
  }
}

@media (max-width: 991px) {
  .tab-box-white {
    border-right: none;
    height: auto;
    display: block;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

@media (max-width: 767px) {
  .filter-options {
    max-height: 0;
  }
  body.opened-filter .filter-options {
    max-height: 1000px;
  }
  .chart-report-height {
    height: 200px;
  }
}

@media (max-width: 575px) {
  .log-in-section {
    background: rgba(255, 255, 255, 0.5);
  }
  .dropdown-select-locations {
    min-width: 260px;
    padding: 10px;
  }
  .pt-fix-12 {
    padding-top: 0;
  }
}

@media (-webkit-min-device-pixel-ratio: 2), (min-resolution: 192dpi) {
}

.filter-options input[type="checkbox"] {
  display: none;
}

.dropdown-select-locations {
  overflow-y: auto;
  height: 300px;
}
.dropdown-select-locations .regionName {
  margin-top: 10px;
}

.htmlForm-group input[type="checkbox"] {
  display: none;
}
.htmlForm-group input[type="radio"] {
  display: none;
}

.profile-category-wrap:hover .ql-editor {
  background-color: #e8f6ff;
}
.disabledRichTextBoxToolbar .ql-editor {
  background-color: white;
}
.disabledRichTextBoxToolbar .ql-toolbar {
  display: none;
  background-color: white;
}
.disabledRichTextBoxToolbar .ql-container {
  border-width: 0px !important;
}

.showConversation {
  color: var(--blue);
  cursor: pointer;
}

.home-d-box {
  transition: all 200ms linear;
}
.home-d-box:hover {
  box-shadow: 0 14px 22px 0 rgba(64, 66, 85, 0.1);
}
.highcharts-tooltip > span {
  padding: 5px !important;
  font-size: 15px !important;
  line-height: 22px !important;
}
.after-h-text {
  font-size: 12px !important;
}
.highcharts-tooltip > span span {
  font-size: 13px !important;
}
.highcharts-tooltip > span b {
  font-size: 16px !important;
}

.knowledgeBaseWidget { font-size: 14px; }
.knowledgeBaseWidget div {
  margin-bottom: 10px;
}
.knowledgeBaseWidget #selectedGroupId {
  font-size: 14px;
}
.sendNewLead {
  padding: 0px !important;
  max-width: 5000px;
}
.sendNewLead { font-size: 14px; }
.sendNewLead div {
  margin-bottom: 0px;
}
.sendNewLead .row {
  margin-left: 0px;
  margin-right: 0px;
}
.sendNewLead .col {
  padding-right: 0px;
  margin-right: 0px;
}
.sendNewLead .formLabel {
  width: 110px;
  padding-left: 0px;
  padding-right: 10px;
}
.sendNewLead .input,
.sendNewLead textarea {
  height: 28px;
  font-size: 14px;
}
.sendNewLead .row {
  margin-top: 5px;
}
.sendNewLead .col {
  width: 100%;
  min-width: 200px;
  padding-left: 0px;
}
.react-time-picker {
  width: 120px;
}
.knowledgeBaseWidget .search {
  margin-bottom: 20px;
  border-radius: 10px;
}
.knowledgeBaseWidget .questionAnswerOuterContainer {
  background-color: white;
  padding: 10px 10px 0 10px;
}
.knowledgeBaseWidget .questionAnswerContainer {
  display: inline-block;
  background-color: white;
  padding: 10px 10px 10px 10px;
}

